import React, {useState} from 'react';
import { Menu, Segment, Sidebar } from 'semantic-ui-react'
import { Link } from "react-router-dom"
import 'semantic-ui-css/components/sidebar.min.css'
import 'semantic-ui-css/components/menu.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faBars, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import {withRouter} from 'react-router-dom';

import AssetsLogoDjarum from './img/logo-djarum.png'

const Layout = (props) => {
  const [visible, setVisible] = useState(false)
  /*
  https://ceritarakyatindonesia.com/lutung_kasarung
  https://ceritarakyatindonesia.com/malin_kunda ng
  https://ceritarakyatindonesia.com/ande_ande_lumut
  https://ceritarakyatindonesia.com/sangi_sang_pemburu
  https://ceritarakyatindonesia.com/timun_mas
  https://ceritarakyatindonesia.com/keong_emas
  https://ceritarakyatindonesia.com/putri_tandampalik
  https://ceritarakyatindonesia.com/empat_raja
  https://ceritarakyatindonesia.com/bawang_merah_bawang_putih
  https://ceritarakyatindonesia.com/pesut_mahakam
  https://ceritarakyatindonesia.com/sigarlaki_dan_limbat
  https://ceritarakyatindonesia.com/asal_usul_danau_batur
  */
 const {location, children} = props
  return (
    <Sidebar.Pushable as={Segment} className={`${(visible) ? 'overflow-hidden' : ''}`}>
      <Sidebar
        as={Menu}
        animation='overlay'
        inverted
        onHide={() => setVisible(false)}
        vertical
        visible={visible}
        width='wide'
        direction='right'
        className='text-right'
      >
        <Menu.Item as='div'>
          <button className="btn no-link" onClick={() => setVisible(false)}><FontAwesomeIcon icon={faChevronLeft} /> Tutup</button>
        </Menu.Item>
        <Menu.Item as='div'>
          <Link to="/lutung_kasarung" onClick={() => setVisible(false)} className="d-block">Lutung Kasarung</Link>
        </Menu.Item>
        <Menu.Item as='div'>
          <Link to="/malin_kundang" onClick={() => setVisible(false)} className="d-block">Malin Kundang</Link>
        </Menu.Item>
        <Menu.Item as='div'>
          <Link to="/ande_ande_lumut" onClick={() => setVisible(false)} className="d-block">Ande Ande Lumut</Link>
        </Menu.Item>
        <Menu.Item as='div'>
          <Link to="/sangi_sang_pemburu" onClick={() => setVisible(false)} className="d-block">Legenda Sangi Sang Pemburu</Link>
        </Menu.Item>
        <Menu.Item as='div'>
          <Link to="/timun_mas" onClick={() => setVisible(false)} className="d-block">Timun Mas</Link>
        </Menu.Item>
        <Menu.Item as='div'>
          <Link to="/keong_emas" onClick={() => setVisible(false)} className="d-block">Keong Emas</Link>
        </Menu.Item>
        <Menu.Item as='div'>
          <Link to="/putri_tandampalik" onClick={() => setVisible(false)} className="d-block">Kisah Putri Tandampalik</Link>
        </Menu.Item>
        <Menu.Item as='div'>
          <Link to="/empat_raja" onClick={() => setVisible(false)} className="d-block">Kisah Empat Raja</Link>
        </Menu.Item>
        <Menu.Item as='div'>
          <Link to="/bawang_merah_bawang_putih" onClick={() => setVisible(false)} className="d-block">Bawang Merah Bawang Putih</Link>
        </Menu.Item>
        <Menu.Item as='div'>
          <Link to="/pesut_mahakam" onClick={() => setVisible(false)} className="d-block">Legenda Pesut Mahakam</Link>
        </Menu.Item>
        <Menu.Item as='div'>
          <Link to="/sigarlaki_dan_limbat" onClick={() => setVisible(false)} className="d-block">Sigarlaki dan Limbat</Link>
        </Menu.Item>
        <Menu.Item as='div'>
          <Link to="/asal_usul_danau_batur" onClick={() => setVisible(false)} className="d-block">Legenda Kebo Iwa dan <br />Asal Usul Danau Batur</Link>
        </Menu.Item>
      </Sidebar>

      <Sidebar.Pusher dimmed={visible} className={`d-flex align-items-stretch`}>
        <div className="position-fixed w-100 text-center py-4" id="Header">
          <div className="container d-flex justify-content-between">
            <button className="btn no-link p-0"><Link to="/" className={(location.pathname === '/') ? 'd-none' : ''}><FontAwesomeIcon icon={faHome} /></Link></button>
            <Link to="/"><img src={AssetsLogoDjarum} alt="Djarum" className="logo w-100" /></Link>
            <button className="btn no-link p-0" onClick={() => setVisible(true)}><FontAwesomeIcon icon={faBars} /></button>
          </div>
        </div>
        {children}
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  )
}

export default withRouter(Layout)