import React from 'react';
import videojs from 'video.js'
import './Video.scss';
import playButton from './img/btn-play.png'

export default class VideoPlayer extends React.Component {
  
  constructor(props){
    super(props);
    this.state = {
      play: false,
      pause: false,
    }
    this.videoPlay = this.videoPlay.bind(this);
    this.videoPause = this.videoPause.bind(this);
  }


  componentDidMount() {
    // instantiate Video.js
    const _ = this;
    this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
      _.videoPause();
      // console.log('onPlayerReady', this)
    });
  }

  videoPlay() {
    if (this.player) {
      this.setState({
        play: false,
        pause: true
      })
      this.player.play();
    }
  }

  videoPause() {
    if (this.player) {
      this.setState({
        play: true,
        pause: false,
      })
      this.player.pause();
    }
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose()
    }
  }

  render() {
    return (
      <div className="videoWrapper">
        <div data-vjs-player>
          <video ref={ node => this.videoNode = node } className="video-js vjs-big-play-centered vjs-16-9" playsInline loop></video>
        </div>
      </div>
    )
  }
}