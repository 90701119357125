import React from 'react'
import {useSpring, animated} from 'react-spring'
import { Link } from "react-router-dom"

import './index.scss'

import Thumbnail1 from './images/thumb-1.jpg'
import Thumbnail2 from './images/thumb-2.jpg'
import Thumbnail3 from './images/thumb-3.jpg'
import Thumbnail4 from './images/thumb-4.jpg'
import Thumbnail5 from './images/thumb-5.jpg'
import Thumbnail6 from './images/thumb-6.jpg'
import Thumbnail7 from './images/thumb-7.jpg'
import Thumbnail8 from './images/thumb-8.jpg'
import Thumbnail9 from './images/thumb-9.jpg'
import Thumbnail10 from './images/thumb-10.jpg'
import Thumbnail11 from './images/thumb-11.jpg'
import Thumbnail12 from './images/thumb-12.jpg'
import BtnPlay from './images/btn-play.png'

export default() => {
  const loading = 1;
  const FadeIn = useSpring({
    from: { opacity: 0, transform: `translate3d(0px, -20px, 0px)` },
    to : async next => {
      while(loading) {
        await next({opacity: 1, transform: `translate3d(0px, 0px, 0px)`})
        await next({ opacity: 1, transform: `translate3d(0px, -10px, 0px)`, config: { duration: 8000}})
      }
    },
    config: { duration: 1000 }
  })

  const PulseFx = useSpring({
    from: { backgroundSize: '100%'},
    to: async next => {
      while(loading) {
        await next({backgroundSize: '110%'})
        await next({backgroundSize: '100%'})
      }
    },
    config: { duration: 15000 }
  })
  return (
    <>
      <animated.div style={PulseFx} className="HomeBGLeft"></animated.div>
      <animated.div style={PulseFx} className="HomeBGRight"></animated.div>
      <div className="d-flex align-items-center justify-content-center w-100">
        <animated.div style={FadeIn} className="text-center IntroSection mt-5 pt-5">
          <div className="container">
            <h1 className="title-page text-uppercase text-center py-3">NILAI LUHUR CERITA RAKYAT INDONESIA</h1>
            <div className="row">
              <div className="col-md-3 col-6 py-3 py-3">
                <div className="thumbnail position-relative">
                <Link to="/lutung_kasarung"><img src={Thumbnail1} className="img-fluid" /></Link>
                  <div className="btn-play position-absolute">
                   <Link to="/lutung_kasarung"><img src={BtnPlay} className="img-fluid"/></Link>
                  </div>
                </div>
                <div className="title text-center text-uppercase pt-2">Lutung Kasarung</div>
              </div>
              <div className="col-md-3 col-6 py-3">
                <div className="thumbnail position-relative">
                <Link to="/malin_kundang"><img src={Thumbnail2} className="img-fluid" /></Link>
                  <div className="btn-play position-absolute">
                  <Link to="/malin_kundang"><img src={BtnPlay} className="img-fluid"/></Link>
                  </div>
                </div>
                <div className="title text-center text-uppercase pt-2">Malin Kundang</div>
              </div>
              <div className="col-md-3 col-6 py-3">
                <div className="thumbnail position-relative">
                  <Link to="/ande_ande_lumut"><img src={Thumbnail3} className="img-fluid" /></Link>
                  <div className="btn-play position-absolute">
                  <Link to="/ande_ande_lumut"><img src={BtnPlay} className="img-fluid"/></Link>
                  </div>
                </div>
                <div className="title text-center text-uppercase pt-2">ande ande lumut</div>
              </div>
              <div className="col-md-3 col-6 py-3">
                <div className="thumbnail position-relative">
                <Link to="/sangi_sang_pemburu"><img src={Thumbnail4} className="img-fluid" /></Link>
                  <div className="btn-play position-absolute">
                  <Link to="/sangi_sang_pemburu"><img src={BtnPlay} className="img-fluid"/></Link>
                  </div>
                </div>
                <div className="title text-center text-uppercase pt-2">legenda sangi sang pemburu</div>
              </div>
              <div className="col-md-3 col-6 py-3">
                <div className="thumbnail position-relative">
                <Link to="/timun_mas"><img src={Thumbnail5} className="img-fluid" /></Link>
                  <div className="btn-play position-absolute">
                  <Link to="/timun_mas"><img src={BtnPlay} className="img-fluid"/></Link>
                  </div>
                </div>
                <div className="title text-center text-uppercase pt-2">timun mas</div>
              </div>
              <div className="col-md-3 col-6 py-3">
                <div className="thumbnail position-relative">
                <Link to="/keong_emas"><img src={Thumbnail6} className="img-fluid" /></Link>
                  <div className="btn-play position-absolute">
                  <Link to="/keong_emas"><img src={BtnPlay} className="img-fluid"/></Link>
                  </div>
                </div>
                <div className="title text-center text-uppercase pt-2">keong emas</div>
              </div>
              <div className="col-md-3 col-6 py-3">
                <div className="thumbnail position-relative">
                <Link to="/putri_tandampalik"><img src={Thumbnail7} className="img-fluid" /></Link>
                  <div className="btn-play position-absolute">
                  <Link to="/putri_tandampalik"><img src={BtnPlay} className="img-fluid"/></Link>
                  </div>
                </div>
                <div className="title text-center text-uppercase pt-2">kisah putri Tandampalik</div>
              </div>
              <div className="col-md-3 col-6 py-3">
                <div className="thumbnail position-relative">
                <Link to="/empat_raja"><img src={Thumbnail8} className="img-fluid" /></Link>
                  <div className="btn-play position-absolute">
                  <Link to="/empat_raja"><img src={BtnPlay} className="img-fluid"/></Link>
                  </div>
                </div>
                <div className="title text-center text-uppercase pt-2">kisah empat raja</div>
              </div>
              <div className="col-md-3 col-6 py-3">
                <div className="thumbnail position-relative">
                <Link to="/bawang_merah_bawang_putih"><img src={Thumbnail9} className="img-fluid" /></Link>
                  <div className="btn-play position-absolute">
                  <Link to="/bawang_merah_bawang_putih"><img src={BtnPlay} className="img-fluid"/></Link></div>
                </div>
                <div className="title text-center text-uppercase pt-2">Bawang Merah <br />Bawang Putih</div>
              </div>
              <div className="col-md-3 col-6 py-3">
                <div className="thumbnail position-relative">
                <Link to="/pesut_mahakam"><img src={Thumbnail10} className="img-fluid" /></Link>
                  <div className="btn-play position-absolute">
                  <Link to="/pesut_mahakam"><img src={BtnPlay} className="img-fluid"/></Link>
                  </div>
                </div>
                <div className="title text-center text-uppercase pt-2">Legenda Pesut Mahakam</div>
              </div>
              <div className="col-md-3 col-6 py-3">
                <div className="thumbnail position-relative">
                <Link to="/sigarlaki_dan_limbat"><img src={Thumbnail11} className="img-fluid" /></Link>
                  <div className="btn-play position-absolute">
                  <Link to="/sigarlaki_dan_limbat"><img src={BtnPlay} className="img-fluid"/></Link>
                  </div>
                </div>
                <div className="title text-center text-uppercase pt-2">Sigarlaki dan Limbat</div>
              </div>
              <div className="col-md-3 col-6 py-3">
                <div className="thumbnail position-relative">
                <Link to="/asal_usul_danau_batur"><img src={Thumbnail12} className="img-fluid" /></Link>
                  <div className="btn-play position-absolute">
                  <Link to="/asal_usul_danau_batur"><img src={BtnPlay} className="img-fluid"/></Link>
                  </div>
                </div>
                <div className="title text-center text-uppercase pt-2">Legenda kebo iwa dan asal usul danau Batur</div>
              </div>
            </div>  
          </div>   
        </animated.div>
      </div>
    </>
  )
}