import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import NotFound from './Pages/NotFound'
import AndeLumut from './Pages/AndeLumut/AndeLumut';
import MalinKundang from './Pages/MalinKundang/MalinKundang';
import SangPemburu from './Pages/SangPemburu/SangPemburu';
import TimunMas from './Pages/TimunMas/TimunMas';
import KeongEmas from './Pages/KeongEmas/KeongEmas';
import LutungKasarung from './Pages/LutungKasarung/LutungKasarung';
import PutriTandampalik from './Pages/PutriTandampalik/PutriTandampalik';
import EmpatRaja from './Pages/EmpatRaja/EmpatRaja';
import Bawang from './Pages/Bawang/Bawang';
import ComingSoon from './Pages/CommingSoon/Layout';
import PesutMahakam from './Pages/PesutMahakam/PesutMahakam';
import SigarLaki from './Pages/SigarLaki/SigarLaki';
import DanauBatur from './Pages/DanauBatur/DanauBatur';
import Home from './Pages/Home/Home'

import Layout from './Layout'
// import ComingSoon from './Pages/ComingSoon';

/*
https://ceritarakyatindonesia.com/lutung_kasarung
https://ceritarakyatindonesia.com/malin_kunda ng
https://ceritarakyatindonesia.com/ande_ande_lumut
https://ceritarakyatindonesia.com/sangi_sang_pemburu
https://ceritarakyatindonesia.com/timun_mas
https://ceritarakyatindonesia.com/keong_emas
https://ceritarakyatindonesia.com/putri_tandampalik
https://ceritarakyatindonesia.com/empat_raja
https://ceritarakyatindonesia.com/bawang_merah_bawang_putih
https://ceritarakyatindonesia.com/pesut_mahakam
https://ceritarakyatindonesia.com/sigarlaki_dan_limbat
https://ceritarakyatindonesia.com/asal_usul_danau_batur
*/

function App() {
  return (
    <div className="App h-100 overflow-hidden" style={{overflow: 'none'}}>
      <Router>
        <Layout name="Sara">
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/lutung_kasarung">
              <LutungKasarung />
            </Route>
            <Route path="/malin_kundang">
              <MalinKundang />
            </Route>
            <Route path="/ande_ande_lumut" exact>
              <AndeLumut />
            </Route>
            <Route path="/sangi_sang_pemburu">
              <SangPemburu />
            </Route>
            <Route path="/timun_mas">
              <TimunMas />
            </Route>
            <Route path="/keong_emas">
              <KeongEmas />
            </Route>
            <Route path="/putri_tandampalik">
              <PutriTandampalik />
            </Route>
            <Route path="/empat_raja">
              <EmpatRaja />
            </Route>
            <Route path="/bawang_merah_bawang_putih">
              <Bawang />
            </Route>
            <Route path="/pesut_mahakam">
              <PesutMahakam />
            </Route>
            <Route path="/sigarlaki_dan_limbat">
              <SigarLaki />
            </Route>
            <Route path="/asal_usul_danau_batur">
              <DanauBatur />
            </Route>
            <Route path="/coming_soon">
              <ComingSoon />
            </Route>
            <Route path="*">
              {/* <NotFound /> */}
              <Redirect to="/" />
            </Route>
          </Switch>
        </Layout>
      </Router>
    </div>
  );
}

export default App;
