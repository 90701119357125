import React from 'react'
import {useSpring, animated} from 'react-spring'
import {withRouter} from 'react-router-dom';

const Layout = (props) => {
  
    const loading = 1;
    const FadeIn = useSpring({
      from: { opacity: 0, transform: `translate3d(0px, -20px, 0px)` },
      to : async next => {
        while(loading) {
          await next({opacity: 1, transform: `translate3d(0px, 0px, 0px)`})
          await next({ opacity: 1, transform: `translate3d(0px, -10px, 0px)`, config: { duration: 8000}})
        }
      },
      config: { duration: 1000 }
    })
  
    const PulseFx = useSpring({
      from: { backgroundSize: '100%'},
      to: async next => {
        while(loading) {
          await next({backgroundSize: '110%'})
          await next({backgroundSize: '100%'})
        }
      },
      config: { duration: 15000 }
    })
    /*
    https://ceritarakyatindonesia.com/lutung_kasarung
    https://ceritarakyatindonesia.com/malin_kunda ng
    https://ceritarakyatindonesia.com/ande_ande_lumut
    https://ceritarakyatindonesia.com/sangi_sang_pemburu
    https://ceritarakyatindonesia.com/timun_mas
    https://ceritarakyatindonesia.com/keong_emas
    https://ceritarakyatindonesia.com/putri_tandampalik
    https://ceritarakyatindonesia.com/empat_raja
    https://ceritarakyatindonesia.com/bawang_merah_bawang_putih
    https://ceritarakyatindonesia.com/pesut_mahakam
    https://ceritarakyatindonesia.com/sigarlaki_dan_limbat
    https://ceritarakyatindonesia.com/asal_usul_danau_batur
    */
   const {location, children} = props
   let bulan='November 2020';
   if(location.pathname=='/malin_kundang'){
     bulan='Februari 2020';
   } else if(location.pathname=='/ande_ande_lumut'){
    bulan='Maret 2020';
  } else if(location.pathname=='/sangi_sang_pemburu'){
    bulan='April 2020';
  } else if(location.pathname=='/timun_mas'){
    bulan='Mei 2020';
  } else if(location.pathname=='/keong_emas'){
    bulan='Juni 2020';
  } else if(location.pathname=='/putri_tandampalik'){
    bulan='Juli 2020';
  } else if(location.pathname=='/empat_raja'){
    bulan='Agustus 2020';
  } else if(location.pathname=='/bawang_merah_bawang_putih'){
    bulan='September 2020';
  } else if(location.pathname=='/pesut_mahakam'){
    bulan='Oktober 2020';
  } else if(location.pathname=='/sigarlaki_dan_limbat'){
    bulan='November 2020';
  }else{
    bulan='Desember 2020';
  }
   console.log(bulan);
    return (
        <div className="w-100 position-relative overflow-hidden">
        <animated.div style={PulseFx} className="HomeBGLeft"></animated.div>
        <animated.div style={PulseFx} className="HomeBGRight"></animated.div>
        <div className="d-flex align-items-center cover justify-content-center">
          <animated.div style={FadeIn} className="text-center IntroSection">
            <h4 className="font-weight-normal mb-0">Nantikan Cerita Selanjutnya</h4>
            <h1 className="mt-0">{bulan}</h1>
          </animated.div>
        </div>
      </div>
    )
  }

export default withRouter(Layout)