import React from 'react'
import {useSpring, animated} from 'react-spring'
import './index.scss'

import ImageVideo from './images/video-timun.jpg'

import VideoPlayer from '../../VideoPlayer'
import dummyVideo from '../../videos/TimunMas.mp4'

export default() => {
  const loading = 1;
  const LeftToRight = useSpring({
    from: { opacity: .5, transform: `scale(1)`, transform: `translate(0px, 0px)`},
    to: async next => {
      while(loading) {
        await next({opacity: 1, transform: `translate(30px, 20px)`})
        await next({opacity: 1, transform: `translate(0px, 0px)`})
      }
    },
    config: { duration: 10000 }
  })

  const videoJsOptions = {
    autoplay: false,
    controls: true,
    sources: {
      src: dummyVideo,
      type: "video/mp4"
    },
    poster: ImageVideo
  }

  const RightToLeft = useSpring({
    from: { opacity: .2, transform: `scale(1)`, transform: `translate(0px, 0px)`},
    to: async next => {
      while(loading) {
        await next({opacity: 1, transform: `translate(-30px, 20px)`})
        await next({opacity: 1, transform: `translate(0px, 0px)`})
      }
    },
    config: { duration: 10000 }
  })
  return(
    <div id="TimunMas" className="w-100 position-relative overflow-hidden">
       <animated.div id="RightTop" style={RightToLeft}></animated.div>
      <animated.div id="rightBottom" style={LeftToRight}></animated.div>
      <div id="Main"></div>
      <div className="video-column text-center position-relative d-flex align-items-center h-100">
        <div className="container">
          <div class="d-flex flex-column">
            <h1 className="title-page text-uppercase text-center pt-0 pt-sm-5 mt-3 mb-0">Timun Mas</h1>
            <div className="thumbnail position-relative col-lg-6 col-12">
              <h3 className="subtitle-page text-uppercase text-center mb-3">(jawa tengah)</h3>
              <VideoPlayer {...videoJsOptions} />
            </div>
          </div>
        </div>          
      </div>
    </div>
  )
}